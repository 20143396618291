/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-13 13:13:21
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-12-22 17:31:39
 */
export enum InboundStatus {
  Incoming = 10,
  Received = 20,
  Matching = 30,
  Matched = 40,
  Mismatched = 50,
  Rejected = 80,
}

export enum InboundDamagedStatus {
  Intact = 10,
  SlightlyDamaged = 20,
  SevereDamaged = 30,
}
